import React from 'react';
import {
  func, string, node, bool,
} from 'prop-types';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';

import { REGISTRATION_START } from 'constants/navigation';

import {
  Box,
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  Button,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const BaseSubscription = ({
  level = '1',
  title, subheader = '',
  smTitle = '', smSubTitle = '',
  avatar,
  description = '',
  content = null,
  looking = false,
  onExpand = () => {},
  expanded = false,
  single = false,
  landing = false,
  name,
}) => {
  const styles = useStyles();
  return (
    <Box
      border={1}
      borderRadius={1}
      className={styles.cardContainer}
      >
    <Card className={styles.landingCard}>
      <CardHeader
        avatar={avatar}
        title={title}
        subheader={subheader}
        onClick={onExpand}
        className={styles.pointer}
        aria-label="click to expand for more information"
        action={
          <IconButton
            className={classNames(styles.expand, {
              [styles.expandOpen]: expanded,
            })}
            onClick={onExpand}
            aria-expanded={expanded}
            aria-label="show more information"
          >
          <FontAwesomeIcon icon={faAngleUp} size="lg" className={styles.infoIcon}/>
          </IconButton>
      }
      />
          {description ? (
        <CardContent className={styles.nomargin}>
            <Typography className={styles.marginLeft2} variant="body1">
             {description}
           </Typography>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
              {content}
              </CardContent>
            </Collapse>
        </CardContent>
          ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
            {content}
            </CardContent>
          </Collapse>
          )}
        <CardActions disableSpacing>
          <Grid spacing={1}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                variant={expanded ? 'contained' : 'outlined'}
                onClick={async () => {
                  if (expanded) {
                    const buildDataLayer = await import('data-layer');
                    const dataLayer = buildDataLayer.default();
                    dataLayer.trackLanding({
                      descriptor: 'navigate_registration',
                      label: name,
                    });
                    navigate(`${REGISTRATION_START}?sub=${name.toLowerCase()}`);
                  } else {
                    onExpand();
                  }
                }}
                className={expanded ? styles.greenbutton : styles.noColorButton}
                aria-label= {expanded ? 'get started with this subscription' : 'show more information' }
                >
                {expanded ? 'GET STARTED' : 'MORE INFORMATION' }
              </Button>
            </Grid>
          </Grid>
        </CardActions>
    </Card>
    </Box>
  );
};

BaseSubscription.propTypes = {
  level: string,
  avatar: node.isRequired,
  title: string.isRequired,
  subheader: string,
  smTitle: string.isRequired,
  smSubTitle: string,
  description: string,
  content: node,
  looking: bool,
  onExpand: func,
  expanded: bool,
  single: bool,
  state: string,
  periodEnd: string,
  landing: bool,
  name: string.isRequired,
};

export default BaseSubscription;
